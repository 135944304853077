
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter, usePagination, formatDateTimeLong } from "@/libs/utils";

const VHistoricList = defineComponent({
  name: "VHistoricList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Histórico de caixa | obmed" });

    const store = useStore();
    const route = useRoute();
    const { hasFilters } = useFilter();

    const historicList = computed(() => store.state.cashier.historic.list);
    const loading = reactive({ list: false });

    function getDropdownOptions() {
      const items: DropdownItem[] = [
        {
          label: "Visualizar",
          icon: "fas fa-info-circle",
          to: { name: "cashier-historic-list" },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Financeiro",
        icon: "fas fa-wallet mr-1",
        to: { name: "cashier-historic-list", query: route.query },
      },
      {
        label: "Histórico",
        icon: "fas fa-history mr-1",
        to: { name: "cashier-historic-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "cashier.historic",
      setPage: "setCashierHistoricPaginationPage",
      updateList: getCashierHistoricList,
    });

    async function getCashierHistoricList() {
      loading.list = true;
      await store.dispatch("getCashierHistoricList");
      loading.list = false;
    }

    getCashierHistoricList();

    return {
      ...{ historicList, pagination, loading, breadcrumb, hasFilters },
      ...{ getDropdownOptions, handleUpdateCurrentPage, getCashierHistoricList },
      ...{ formatDateTimeLong },
    };
  },
});

export default VHistoricList;
